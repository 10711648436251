/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/rubik-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/rubik-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/rubik-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/rubik-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/rubik-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/rubik-v14-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
body{
  margin: 0px !important;
}
.MuiDialogContent-root .MuiAutocomplete-popup {
    z-index: 1300 !important;
}

.popupautocomplete .MuiDialog-container .MuiPaper-root{
  min-height: fit-content !important;
  max-height: max-content !important;
}
/* .MuiAutocomplete-popup {
  z-index: 999999 !important;
} */
.popupautocomplete .MuiAutocomplete-listbox {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 8px 0;
  max-height: fit-content;
  overflow: visible;
  background: #fff;
  max-height: 200px !important;
  overflow-y: scroll !important;
}
.popupautocomplete .MuiAutocomplete-popper{
  position: initial !important;
  transform: translate3d(0px, 0px, 0px)!important;
  animation: fade 1s ease forwards;
  transition-delay: 1s;
  

}

@media screen and (max-width: 1024px) {
  .w-md-editor-toolbar{
    height: 45px !important;
  }
}

.twitchesLoading {
	background-size: 400% 400%;
	animation: gradient 1s ease infinite;
	height: 100vh;
}

.dot{
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-left: 5px;
}
.grayDot {
  background-color: #bbb;
}
.lightBlueDot {
  background-color: #80e5ff;
}
.redDot {
  background-color: red;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/************ Themes ***********/
.dark-mode{
  background-color: #010001;
}
.dark-mode .MuiAutocomplete-popper .MuiPaper-root ul {
  background-color: #313133 !important;
  color: #ffffff;
}

.dark-mode .profileMenu ul{
  background-color: #313133 !important;
  color: #ffffff;
}
.dark-mode .w-md-editor.w-md-editor-show-edit {
  background-color: rgba(0, 0, 0, 0.5) !important;
  caret-color: #5BA2FF;
}
.dark-mode .wmde-markdown-color code[class*="language-"] {
  color: #ffffff;
}
.dark-mode .w-md-editor-toolbar li > button {
  color: #ffffff;
}
.dark-mode .w-md-editor-toolbar{
  background-color: black;
  border-bottom: 1px solid black;
}
.dark-mode input:-webkit-autofill,
.dark-mode input:-webkit-autofill:hover, 
.dark-mode input:-webkit-autofill:focus, 
.dark-mode input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #211F24 inset !important;
}

.dark-mode input:-webkit-autofill{
  -webkit-text-fill-color: #ffffff !important;
}
.dark-selected-sidebar-item{
  color: rgb(0, 0, 0) !important;
  border-left: solid 3px rgb(159, 0, 207) !important;
  border-radius: 0px !important;
  background-color: rgba(25, 118, 210, 0.08) !important;
  width: 100% !important;
  color: #ffffff !important;
}
/*****************Light Mode*********************/
.light-mode{
  background-color: #dedfe0;
}
.light-mode .MuiAutocomplete-popper .MuiPaper-root ul {
  background-color: #dedfe0 !important;
  color: #000000;
}

.light-mode .profileMenu ul{
  background-color: #dedfe0 !important;
  color: #000000;
}

.light-mode .w-md-editor.w-md-editor-show-edit {
  background-color: rgba(255, 255, 255, 0.5) !important;
  caret-color: #1976d2;
}
.light-mode .wmde-markdown-color code[class*="language-"] {
  color: #000000;
}
.light-mode .w-md-editor-toolbar li > button {
  color: #000000;
}
.light-mode .w-md-editor-toolbar{
  background-color: #dedfe0;
  border-bottom: 1px solid #dedfe0;
}
.light-mode input:-webkit-autofill,
.light-mode input:-webkit-autofill:hover, 
.light-mode input:-webkit-autofill:focus, 
.light-mode input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.light-mode input:-webkit-autofill{
  -webkit-text-fill-color: #000000 !important;
}

.light-selected-sidebar-item{
  color: rgb(0, 0, 0) !important;
  border-left: solid 3px rgb(159, 0, 207) !important;
  border-radius: 0px !important;
  background-color: #dedfe0 !important;
  width: 100% !important;
}

/************ Themes ***********/
.create-group-dd .MuiAutocomplete-popper .MuiPaper-root ul {
  max-height: 120px;
  overflow-y: scroll;
}


/*** MUI DataTable Pagination Mobile Padding***/
.MuiTablePagination-toolbar {
  padding-left: 0% !important;
}